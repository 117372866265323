import React, { useState } from "react";
import CashOfficeSection from "../../components/MaintenanceSections/CashOfficeSection/CashOfficeSection";
import FrontlineSection from "../../components/MaintenanceSections/FrontlineSection/FrontlineSection";
import SecuritySection from "../../components/MaintenanceSections/SecuritySection/SecuritySection";
import ManagementSection from "../../components/MaintenanceSections/ManagementSection/ManagementSection";
import './AuditMaintenance.css'

const AuditMaintenance = () => {
    // State to track the active tab
    const [activeTab, setActiveTab] = useState("FrontlineSection");
  
    // Function to switch the active tab
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };

    return (
        <div>
          <h4 className="auditTitle">Audit Maintenance</h4>
          <div className="tab-container">
            {/* Horizontal tabs */}
           
          
            <div
              className={`tab ${activeTab === "FrontlineSection" ? "active" : ""}`}
              onClick={() => handleTabClick("FrontlineSection")}
            >
              Frontline Section
            </div>
            <div
              className={`tab ${activeTab === "SecuritySection" ? "active" : ""}`}
              onClick={() => handleTabClick("SecuritySection")}
            >
              Security Section
            </div>
            <div
              className={`tab ${activeTab === "ManagementSection" ? "active" : ""}`}
              onClick={() => handleTabClick("ManagementSection")}
            >
              Management Section
            </div>          
            <div
              className={`tab ${activeTab === "CashOfficeSection" ? "active" : ""}`}
              onClick={() => handleTabClick("CashOfficeSection")}
            >
              Cash Office Section
            </div> 
            
          </div>
    
          {/* Render the selected section */}
          
          {activeTab === "FrontlineSection" && <FrontlineSection />}
          {activeTab === "SecuritySection" && <SecuritySection />}
          {activeTab === "ManagementSection" && <ManagementSection />}
          {activeTab === "CashOfficeSection" && <CashOfficeSection />}
          {/* Add conditions for other sections similarly */}
        </div>
      );
    };

export default AuditMaintenance;