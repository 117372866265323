import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const lpaFindingsState = atom({
  key: 'lpaFindingsState',
  dangerouslyAllowMutability: true,
  default: [],
  effects_UNSTABLE: [persistAtom],
  // Add the following line to your code to clear the default state:
  initializeState: ({ set }) => {
    set(lpaFindingsState, []);
  },
});