import React from 'react';
import './SuperAdminDashboard.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CreateUsers from '../../components/Create/CreateUsers/CreateUsers';
import ViewUsers from '../../components/View/ViewUsers/ViewUsers';
import CreateSites from '../../components/Create/CreateSites/CreateSites';
import ViewSites from '../../components/View/ViewSites/ViewSites';
import ChangeStatus from '../../components/View/ChangeStatus/ChangeStatus';

const SuperAdminDashboard = () => {
    
return (
    
  <div className='superAdminContainer'>  

    <Tabs>
      <div className='transSU'></div>
      <TabList className='tabsTitle'>
        <Tab>Users</Tab>
        <Tab>Sites</Tab>
      </TabList>

        <TabPanel >
        <div className='createColumn'>
          <CreateUsers/>
        </div>
        <div className='viewColumn'>
          <ViewUsers/>
        </div>            
        </TabPanel>

        
        <TabPanel>
          <div className='createColumn'>
            <CreateSites/>
          </div> 
          <div className='viewColumn'>
            <ViewSites/>
          </div>
        </TabPanel>

    </Tabs>
  
  </div>         
 
)
       
    
}
export default SuperAdminDashboard;