import React, { useState } from "react";
import axios from "axios";
import {
	Container,
	Button,
	Grid,
	Paper,
} from "@mui/material";
import './CreateUsers.css'

const CreateUsers = () => {


  const [message, setMessage] = useState("");
const [errorMessage, setErrorMessage] = useState("");

  const [data, setData] = useState({
    username: "",
    password:"",
    confirmPassword:"",
    role:"",
  })

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
    
 }
  const handleSubmit=(e)=>{
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      setErrorMessage("Passwords do not match.");
    return;
    }

   const sendData={
      username:data.username,
      password:data.password,
      role:data.role,
    }
    console.log(sendData)
    axios.post("https://myworxit.co.za/api/audits/createUsers.php",sendData)
    .then((res)=> {
      setMessage(res.data.message);   
      setData({username:"",password:"",confirmPassword:"", role: ""})   
    })
    .catch((err) => {
      console.error(err);
      setErrorMessage("An error occurred.");
    });
  }

  return (
    <div className="register-form" >
		<Container maxWidth="sm" >
		<Grid
		container
		spacing={2}
		direction="column"
		justifyContent="center"
		style={{ minHeight: "60vh", }}
	>
	<Paper elelvation={2} sx={{ padding: 5 }}>
	<form  onSubmit={handleSubmit}>
	<Grid container direction="column" spacing={2}>
    <h4 className="regTitle">Create User</h4>
		<Grid item>
			<input
				type="text"
        name="username"
				placeholder="Username"
				variant="outlined"
        required
        value={data.username}
        onChange={handleChange}
			/>
		</Grid>

		<Grid item>
		<input
			type= "password"
      name="password"
			placeholder="Password"
			variant="outlined"
			required
      value={data.password}
			onChange={handleChange}
			
		/>
		</Grid>

    <Grid item>
      <input
        type= "password"
        name="confirmPassword"
        placeholder="Confirm Password"
        variant="outlined"
        required
        value={data.confirmPassword}
        onChange={handleChange}
        
      />
		</Grid>
    <Grid item>
      <input
        type= "text"
        name="role"
        placeholder="Role"
        variant="outlined"
        required
        value={data.role}
        onChange={handleChange}
        
      />
		</Grid>
		<Grid item>
        <Button type="submit" fullWidth variant="contained">
			Create User
		</Button>
    {message && <p className="message">{message}</p>}
    {errorMessage && <p className="errorMsg">{errorMessage}</p>}
		</Grid>
	</Grid>
	</form>
	</Paper>
	</Grid>
	
	</Container>
			</div> 
  );
};

export default CreateUsers;
