import React from "react";
import { RecoilRoot } from "recoil";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./Pages/Login/Login";
import NotFound from "./components/NotFound/NotFound"
import Sidebar from "./components/Sidebar/Sidebar";
import Protected from "./services/Protected";
import SuperAdminDashboard from './Pages/SuperAdminDashboard/SuperAdminDashboard';
import WorxitAdminDashboard from "./Pages/WorxitAdminDashboard/WorxitAdminDashboard";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import EditUser from "./components/Edit/EditUser/EditUser";
import EditSite from "./components/Edit/EditSite/EditSite";
import NoAccess from "./Pages/NoAccess/NoAccess";
import StoreAudit from "./Pages/StoreAudit/StoreAudit";
import Findinglogs from "./components/FindingsLogs/findingsLogs"
import Feedback from './components/FeedbackPage/feedbackPage';
import AuditMaintenance from "./Pages/AuditMaintenance/AuditMaintenance";

const AppRoutes = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('status') === '1'; //Change this to a atom at a later stage
  return (

    <>
      <RecoilRoot>
        {location.pathname !== "/" && isLoggedIn && (
          <>
            <Sidebar>
              <Routes>
              <Route path="/superAdmin" element={
                <Protected>
                  <SuperAdminDashboard />
                </Protected>
              } />
              <Route path="/admin" element={
                <Protected>
                  <WorxitAdminDashboard />
                </Protected>
              } />
              <Route path="/manager" element={
                <Protected>
                  <AdminDashboard />
                </Protected>
              } />               
               <Route path="/editUsers/:id" element={
                <Protected>
                  <EditUser/>
                </Protected>
              } />
             
                <Route path="/editSite/:id" element={
                <Protected>
                  <EditSite/>
                </Protected>
              } />
               <Route path="/storeAudit" element={
                <Protected>
                  <StoreAudit/>
                </Protected>
              } />
            
                 <Route path="/findingsLogs" element={
                <Protected>
                  <Findinglogs/>
                </Protected>
              } />
               <Route path= "/feedback/:auditCode" element={
                <Protected>
                  <Feedback/>
                </Protected>
              } />
              <Route path="/auditMaintenance" element={
                <Protected>
                  <AuditMaintenance/>
                </Protected>
              } />

              </Routes>
            </Sidebar>
          </>
        )}
         {location.pathname === "/" && <Login />}
        {!isLoggedIn && (
          <Routes>
            <Route path="/noAccess" element={<NoAccess />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        )}
      </RecoilRoot>
    </>
  );
};
export default AppRoutes;
