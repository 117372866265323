import React from 'react';
import AppRoutes from './routes';
import './styles/custom.css';
import './styles/foundation.min.css';

 const App=()=>   {  

  return (      

    <div className="off-canvas-wrapper">
      <div className="off-canvas-wrapper-inner" data-off-canvas-wrapper>      
        <div className="off-canvas-content" data-off-canvas-content> 
          <AppRoutes/>             
        </div>
      </div>
    </div> 
  );
}

export default App;

