import React from "react";
import { Navigate } from "react-router-dom";

const Protected = ({children}) => {
    const user = localStorage.getItem("username");

    if(!user){
        return <Navigate to="/" replace />;
    }
       
    return children;
        
    
};


export default Protected;