import React, { useEffect, useState } from 'react';
import {  useNavigate,Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../media/boxerLogo.png';
import logoutBtn from '../../media/logoutBtn.png';
import Badge from '@mui/material/Badge';
import axios from 'axios'
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";

import { auditHeaderDataAtom } from '../../components/RecoilStates/auditHeaderDataAtom';
import { auditLogsAtom } from '../../components/RecoilStates/auditlogsAtom';
import { lpaFrontlineQuestionsState } from '../../components/RecoilStates/LpaFrontlineQuestionsState';
import { lpaSecurityQuestionsState } from '../../components/RecoilStates/LpaSecurityQuestionsState';
import { lpaManagementQuestionsState } from '../RecoilStates/LpaManagementQuestionsState';
import { lpaCashOfficeQuestionsState } from '../../components/RecoilStates/LpaCashOfficeQuestionsState';


export default function Navbar ()  {

const [auth, setAuth]= useState();
const navigate = useNavigate();

const resetAuditHeaderDataAtom = useResetRecoilState(auditHeaderDataAtom);
const resetAuditLogsAtom = useResetRecoilState(auditLogsAtom);
const resetLpaFrontlineQuestions = useResetRecoilState(lpaFrontlineQuestionsState);
const resetLpaSecurityQuestions = useResetRecoilState(lpaSecurityQuestionsState);
const resetLpaManagementQuestions = useResetRecoilState(lpaManagementQuestionsState);
const resetLpaCashOfficeQuestions = useResetRecoilState(lpaCashOfficeQuestionsState);
   
          

    useEffect(()=>{
    var auth = localStorage.getItem('username');
    setAuth(auth)
    },
    [])

    const LogOut= () =>{
      localStorage.removeItem('username');
      localStorage.clear();
      resetAuditHeaderDataAtom();
        resetAuditLogsAtom();
        resetLpaCashOfficeQuestions();
        resetLpaFrontlineQuestions();
        resetLpaSecurityQuestions();
        resetLpaManagementQuestions();
      navigate('/');
      window.location.reload();
    }

   
    
     
   

return (
    
    <div className="title-bar ">
      <div className="title-bar-left">    
        <div>  
          <span>
            <img className='logoSize' src={logo} alt="Logo" />
          </span>
        </div>
      </div>
      
      <div className="title-bar-right"> 
        <span className="title-bar-title">
          Welcome: {auth} 
          <img className="logoutBtn" src={logoutBtn} alt="Logo" onClick={LogOut}/> 
        </span>
      </div>
    </div>
  );
}

// export default Navbar;
