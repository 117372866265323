import React from 'react';
import './AdminDashboard.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ViewUsers from '../../components/View/ViewUsers/ViewUsers';
import CreateSites from '../../components/Create/CreateSites/CreateSites';
import ViewSites from '../../components/View/ViewSites/ViewSites';

const AdminDashboard  = () => {
    
return (
    
  <div className='adminContainer'> 

    <Tabs>
      <div className='trans'></div>
      <TabList className='tabsTitle'>
        <Tab>Users</Tab>
        <Tab>Sites</Tab>
      </TabList>

      <TabPanel >
       
        <div className='viewColumn'>
          <ViewUsers/>
        </div>       
      </TabPanel>

      <TabPanel>
        <div className='createColumn'>
          <CreateSites/>
        </div> 
        <div className='viewColumn'>
          <ViewSites/>
        </div>
      </TabPanel>

    </Tabs>
  
  </div>         
 
)
       
    
}
export default AdminDashboard ;