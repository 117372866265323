import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "../../../styles/LpaStoreAnalysisStyles.css";
import { lpaFindingsState } from '../../RecoilStates/LpaFindingsState';

const LpaFindings = () => {
  const { auditCode } = useParams();
  const setLpaFindingsDetails = useSetRecoilState(lpaFindingsState);
  const lpaFindingsDetails = useRecoilValue(lpaFindingsState);
 
  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);
  
  
  useEffect(() => {
    // Fetch data from the API and update LPAQuestions in the atom
    const fetchData = async () => {
      try {
        const response = await axios.get('https://myworxit.co.za/api/audits/viewFindingsLogs.php');
        const newData = response.data.records;
        // Update the LPAQuestions in the atom if newData is an array
        if (Array.isArray(newData)) {
          setLpaFindingsDetails(newData);
       
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData()
      .then(() => setIsDataReady(true)) // Set isDataReady to true when the data is available
      .catch(() => setIsDataReady(true)); // Also set isDataReady to true if there's an error to prevent an infinite loading state
  }, [setLpaFindingsDetails]);


  if (!Array.isArray(lpaFindingsDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  const filteredAdminDetails = lpaFindingsDetails.filter(details => details.auditCode === auditCode);

  if (!isDataReady) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

   
    return (
        <div className='questionContainerFeedback'>
            <div className='titleHeader'>
                <h4 className='titleh4'>Alerts</h4>
            </div>
            <table className='lpaStoreAnalysisTbl' id='findingsTblID'>
            <thead className='lpaStoreAnalysisHeader'>
                <tr className='lpaStoreAnalysisHeaderRow'>
                    <th className="typeColWidth">Type</th>
                    <th className="responsesColWidth">Responses</th>
                    <th className="dueDateColWidth">Captured Images</th>
                    <th className="actionColWidth">Action</th>
                    <th className="responPersColWidth">Responsible Person</th>
                    <th className="dueDateColWidth">Due Date</th>
                </tr>
            </thead>
            <tbody className='lpaStoreAnalysisBody'>
            {filteredAdminDetails
                .map((details, index) => (
                <tr className='lpaStoreAnalysisBodyRow' key={index}>
                    <td className="typeColWidth">
                      <div className='failCircle'></div>
                  </td>
                    <td className="responsesColWidth">
                    <div>{details.questions} - {details.responses}</div>
                    </td>
                    <td className="dueDateColWidth">
                      <img style={{width:'116px'}} src={details.capturedImages} alt=''/>
                    </td>
                    <td className="actionColWidth">{details.actions}</td>
                    <td className="responPersColWidth">{details.responsPerson}</td>
                    <td className="dueDateColWidth">{details.dueDate}</td>
                </tr>
            ))}
            </tbody>
        </table>
        </div>
   
    )
}

export default LpaFindings;