import React, { useEffect ,useState } from 'react'
import './Sidebar.css';
import {
  FaBox,
  FaBoxes,  
  FaWpforms,
  FaTruck,
  FaBoxOpen,
  FaRunning,
  FaUserShield,
  FaUserEdit,
  FaUserLock,
  FaRegCalendarCheck,
  FaRegCalendarTimes,
  FaPallet
} from "react-icons/fa";
import { NavLink} from 'react-router-dom'; 
import Navbar from '../Navbar/Navbar';


const Sidebar=({children})=>{ 
  
  const [activeLinkClass, setActiveLinkClass] = useState("");
    const[isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen (!isOpen);   
    

    const [showSuperAdminDashboard, setShowSuperAdminDashboard]= useState(false); 
    const [showAdminDashboard, setShowAdminDashboard] = useState(false);
    const [showManagerDashboard, setShowManagerDashboard] = useState(false);

  useEffect(()=>{
      
    const role = localStorage.getItem('role');
      if(role === 'superAdmin'){
        setShowSuperAdminDashboard(true)
      }
      if(role === 'Admin'){
        setShowAdminDashboard(true)
      }
      if(role === 'Manager'){
        setShowManagerDashboard(true)
      }
    },[])
    

    const menuitem =[

      {
        path: "/storeAudit",
        name:"Store Audits ",
        icon:<FaWpforms/>,
        className: "storeAudit-link"
       },

      // {
      //   path: "/orders",
      //   name:"Orders",
      //   icon:<FaBoxes/>,
      //   className: "orders-link",
      //   disabled: true
      // },
  
      // {
      //   path: "/dispatched",
      //   name:"Dispatched",
      //   icon:<FaTruck/>,
      //   className: "dispatched-link",
      //   disabled: true

      // },

      //Receiving/Deliveries
    //   {
    //   path: "/deliveries",
    //   name:"Completed Orders",
    //   icon:<FaBox/>,
    //   className: "deliveries-link",
    //   disabled: true

    //  },

    //  {
    //   path: "/suppliers",
    //   name:"Suppliers",
    //   icon:<FaRunning/>,
    //   className: "suppliers-link",
    //   disabled: true
    //  },

    //  {
    //   path: "/returned",
    //   name:"Returns",
    //   icon:<FaBoxOpen/>,
    //   className: "returned-link",
    //   disabled: true
    //  },

    //  {
    //   path: "/claims",
    //   name:"Claims  ",
    //   icon:<FaWpforms/>,
    //   className: "claims-link",
    //   disabled: true
    //  },

    //  {
    //   path: "/pallets",
    //   name:"Pallets",
    //   icon:<FaPallet/>,
    //   className: "pallets-link",
    //   disabled: true
    //  },

    //  {
    //   path: "/ordersProgress",
    //   name:"Orders Progress  ",
    //   icon:<FaRegCalendarTimes/>,
    //   className: "ordersProgress-link",
    //   disabled: true
    //  },

    
       
        ]

        useEffect(() => {
          const onLinkClick = (e) => {
            const className = e.target.parentElement.className;
            setActiveLinkClass(className);
          }
      
          const links = document.querySelectorAll(".link");
          links.forEach(link => {
            link.addEventListener("click", onLinkClick);
          });
      
          return () => {
            links.forEach(link => {
              link.removeEventListener("click", onLinkClick);
            });
          }
        }, []);

      return ( 
     
      <div className="container">   
      <div style={{width: "165px"}} className="sidebar ">    
        <div className="top-section"></div>                      
          { menuitem.map((item, index) => (
            <NavLink to={item.path} key={index} className={`link ${item.className} ${item.disabled ? 'disabled' : ''}`} activeClassName="active-link">
              <div className="icon-text">
                <div className="icon">{item.icon}</div>
                <div style={{ display: "block" }} className="link_text">
                  {item.name}
                </div>
              </div>
            </NavLink>           
          ))                 
        }      
{/* 
     {showSupervisorDashboard && (  
        <NavLink to={"/supervisor"} className="link supervisor-link" activeclassname="active-link">
          <div className="icon-text">
            <div className="icon"><FaUserEdit /> </div>                        
              <div style={{display: "block"}} className="link_text">Supervisor</div> 
          </div>                      
        </NavLink>                                   
      )}            */}
      {showAdminDashboard && (  
        <NavLink to={"/admin"} className="link admin-link" activeclassname="active-link">
          <div className="icon-text">
            <div className="icon"><FaUserLock style={{color: 'white'}}/> </div>                        
              <div style={{display: "block"}} className="link_text">WorxIt Admin</div> 
          </div>                      
        </NavLink>                                   
      )}   
      {showManagerDashboard && (  
        <NavLink to={"/manager"} className="link manager-link" activeclassname="active-link">
          <div className="icon-text">
            <div className="icon"><FaUserShield/> </div>                        
              <div style={{display: "block"}} className="link_text">Admin</div> 
          </div>                      
        </NavLink>                                   
      )}  
      {showSuperAdminDashboard && (  
        <NavLink to={"/manager"} className="link manager-link" activeclassname="active-link">
          <div className="icon-text">
            <div className="icon"><FaUserShield/> </div>                        
              <div style={{display: "block"}} className="link_text">Admin</div> 
          </div>                      
        </NavLink>                                   
      )}   
        {showSuperAdminDashboard && (  
        <NavLink to={"/admin"} className="link admin-link" activeclassname="active-link">
          <div className="icon-text">
          <div className="icon"><FaUserLock/> </div>                    
              <div style={{display: "block"}} className="link_text">WorxIt Admin</div> 
          </div>                      
        </NavLink>                                   
      )}    
       {showSuperAdminDashboard && (  
        <NavLink to={"/superAdmin"} className="link superAdmin-link" activeclassname="active-link">
          <div className="icon-text">
            <div className="icon"><FaUserLock/> </div>                        
              <div style={{display: "block"}} className="link_text">Super User</div> 
          </div>                      
        </NavLink>                                   
      )}                                     
    </div>  
      <main className={`main ${window.location.pathname.split("/")[1]}`}>
        <div className="navBar"><Navbar/></div>
          <div className="pages">{children}</div>
      </main> 
  </div>
  );
};
export default Sidebar;


