import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const lpaManagementDataState = atom({
  key: 'lpaManagementDataState',
  default: {
    responses: [],
    notes: [], 
    scores: [],
    totalScore: 100,
  },
  effects_UNSTABLE: [persistAtom],
});

