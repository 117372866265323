import React from 'react';
import './NoAccess.css';

const NoAccess  = () => {
    
return (
    
    <div className='adminContainer'>            
     <h6 style={{fontSize:30, color:"white"}}>No Access to user</h6>
    </div>         
 
)
       
    
}
export default NoAccess ;