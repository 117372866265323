import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "../../../styles/LpaStoreAnalysisStyles.css";
import { lpaManagementDetailsState } from '../../RecoilStates/LpaManagementDetailsState';

const LpaManagmentFeedback = () => {
  const { auditCode } = useParams();

  const lpaManagementDetails = useRecoilValue(lpaManagementDetailsState);
  
  useEffect(() => {
    console.log(lpaManagementDetails);
    }, [lpaManagementDetails]);

  // Check if lpaAdminDetails is an array before using map()
  if (!Array.isArray(lpaManagementDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  // Filter lpaAdminDetails based on the auditCode
  const filteredManagementDetails = lpaManagementDetails.filter(details => details.auditCode === auditCode);

    return (
        <div className='questionContainerFeedback'>
            <div className='titleHeader'>
                <h4 className='titleh4'>LPA Store Analysis : Management</h4>
            </div>
            <table className='lpaStoreAnalysisTbl'>
                <thead className='lpaStoreAnalysisHeader'>
                    <tr className='lpaStoreAnalysisHeaderRow'>
                        {/* <th className="typeWidth">Type</th> */}
                        <th className="scoreTypeColumnWidth">Type</th>
                        <th className="questionsColumnWidth">LPA Questions</th>
                        <th className="answersColumnWidth" >Observation/Responses</th>
                        <th className="notesColumnWidth">Notes </th>
                    </tr>
                </thead>
                <tbody className='lpaStoreAnalysisBody'>
                    {filteredManagementDetails.map((details, index) => (
                        <tr className='lpaStoreAnalysisBodyRow' key={index}>
                        <td className="scoreTypeColumnWidth">
                            {details.scores === 'Pass' ? (
                                <div className='passCircle'></div>
                            ) : details.scores === 'Fail' ? (
                                <div className='failCircle'></div>
                            ) : (
                                <div className='naCircle'></div>
                            )}
                        </td>
                        <td className="questionsColumnWidth">{details.question}</td>
                        <td className="answersColumnWidth">{details.responses} </td>
                        <td className="notesColumnWidth">{details.notes} </td>
                        </tr>
                    ))}
                    </tbody>
            </table>  
            <div className='titleFooter'>
                {filteredManagementDetails.length > 0 && (
                    <h4 className='titleFooterH4'>LPA Management Score = {filteredManagementDetails[0].totalScore}%</h4>
                )}
            </div>
        </div>
   
    )
}

export default LpaManagmentFeedback;