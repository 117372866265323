import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import "./ViewUsers.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import {
	Container,
	Grid,
	Paper,
} from "@mui/material";



const ViewUsers = () =>{

    const [viewUsers, setViewUsers]= useState([]);
    const [loading, setLoading] = useState(false);
    const [searchColumn, setSearchColumn] = useState(""); 
    const [searchKeyword, setSearchKeyword] = useState(""); 
    const [message, setMessage] = useState("");
      
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const usersPerPage = rowsPerPage;
    const [pageNumber, setPageNumber] = useState(0);

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPageNumber(0); // Reset the page number to 0
    };

    
    const usersTable = async () => {
      const response = await axios.get('https://myworxit.co.za/api/audits/viewUsers.php');
      const newData = response.data.records;
      setViewUsers(newData);
    };
    useEffect(() => {
      
      usersTable();
      const intervalId = setInterval(usersTable, 3000);
      return () => clearInterval(intervalId);
    }, []);
    
    
    const filteredSearch = viewUsers.filter((user) => {
      if (searchColumn !== "") {
        return user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase());
      }
      // If "All" is selected, check if any column contains the search keyword
      return Object.values(user).some((value) =>
        value.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    });
      
    useEffect(() => {
      if (filteredSearch.length === 0 && searchKeyword !== "") {
        setMessage("No Match Found");
      } else {
        setMessage("");
      }
    }, [filteredSearch, searchColumn, searchKeyword]);


      const deleteCompany = (id) =>{
        axios.delete("https://myworxit.co.za/api/audits/deleteUser.php", {data: {id: id}})
        .then(()=>{
          usersTable();
        }).catch(()=>{
            alert("Error, Cannot Delete User!");
        })
    }

    return(
        
        <div className="userDetails">
      <Container maxWidth="sm" >
        <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        style={{ minHeight: "30vh", }}
      >
      <Paper elelvation={2} sx={{ padding: 5 }}>
      <Grid container direction="column" spacing={2}>
        <Grid>
        <div className="row filter">
          <div className="column1Users">
            <h5>Search By:</h5>
          </div>
          <div className="column2Users">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="username">Username</option>
              <option value="role">Role</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Username</th>
                    <th>role</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {loading ? (
                      <tr>
                        <td colSpan={11}>Loading...</td>
                      </tr>
                    ) : (        
                      filteredSearch
                        // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                        .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                        .map((viewUsers, index) =>(
                            <tr key={index}>                                  
                                <td>{viewUsers.username}</td> 
                                <td>{viewUsers.role}</td> 
                                <td>
                                  <div
                                    className={`statusCircle ${viewUsers.status === '1' ? 'green' : 'red'}`}
                                  ></div>
                                </td>
                                <td>
                                <Link to={`/editUsers/${viewUsers.id}`}><EditIcon/>
                                </Link> 
                                <button onClick={() => deleteCompany(viewUsers.id)}>< DeleteForeverIcon sx={{ color: red[900] }}/></button>
                                
                                </td>
                            </tr>
                            ))
            )}
                </tbody>
            </table>   
            <div className="rows-per-page">
              <label className="lblRowCo">Rows per page:</label>
              <select className="pageRowsCo" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredSearch.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>} 
            </Grid>
      </Grid>
      </Paper>
      </Grid>
      
      </Container>
        </div>
        
        
    )
}

export default ViewUsers;