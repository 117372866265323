import React, { useState, useEffect } from "react";
import { lpaFrontlineQuestionsState } from "../../RecoilStates/LpaFrontlineQuestionsState";
import { FaEdit, FaTimes } from 'react-icons/fa';
import { useRecoilValue } from "recoil";
import axios from 'axios';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';

const FrontlineSection = (args) => {
  const frontlineQuestionsObj = useRecoilValue(lpaFrontlineQuestionsState);
  const [newQuestion, setNewQuestion] = useState("");
  const [frontlineQuestions, setFrontlineQuestions] = useState(frontlineQuestionsObj.LPAQuestions);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedQuestionId, setEditedQuestionId] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);

  useEffect(() => {
    // Fetch the updated questions from the server when the component mounts
    fetchQuestionsFromServer();
  }, []);

  if (!Array.isArray(frontlineQuestions)) {
    console.error("frontlineQuestions is not an array:", frontlineQuestions);
    return null; // Handle the error gracefully
  }

  /// Function to fetch questions from the server
  const fetchQuestionsFromServer = async () => {
    try {
      const response = await axios.get('https://myworxit.co.za/api/audits/frontlineSectionQuestions.php');
      const newData = response.data.records;
      // Update the LPAQuestions in the atom
      setFrontlineQuestions(newData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  // Function to add a new question to the server
  const handleAddQuestion = async () => {
    if (newQuestion.trim() === "") {
      return; // Prevent adding empty questions
    }

          // Find the highest quesId and increment it
          const maxQuesId = frontlineQuestions.reduce((maxId, question) => {
            const idNumber = parseInt(question.quesId.replace('QC', ''), 10);
            return Math.max(maxId, idNumber);
          }, 0);
      
        const newQuesId = `QC${maxQuesId + 1}`;

    try {
      const response = await fetch("https://myworxit.co.za/api/audits/addFrontlineQuestions.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          quesId: newQuesId,
          questions: newQuestion }), // Send the new question to the server
      });

      if (response.ok) {
        // Clear the input field
        setNewQuestion("");
        setShowAddQuestion(false); // Hide the input field after adding the question

        // Fetch the updated questions from the server
        fetchQuestionsFromServer();
      } else {
        console.error("Failed to add question to the server");
      }
    } catch (error) {
      console.error("Error adding question:", error);
    }
  };

  const handleEditClick = (question, quesId) => {
    // Set the edited question and ID, and show the edit modal
    setEditedQuestion(question);
    setEditedQuestionId(quesId);
    setShowEditModal(true);
  };

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
  
    const updatedData = {
      quesId: editedQuestionId,
      questions: editedQuestion,
    };
  
    try {
      const response = await axios.post("https://myworxit.co.za/api/audits/updateFrontlineQuestion.php", updatedData);
      
      if (response.data.status === 'valid') {
        // Update the specific question in adminQuestions
        setFrontlineQuestions((prevQuestions) => {
          return prevQuestions.map((question) => {
            if (question.quesId === editedQuestionId) {
              // Update the questions field of the specific question
              return { ...question, questions: editedQuestion };
            }
            return question;
          });
        });
  
        setShowEditModal(false);
      } else {
        console.error("Failed to update question.");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };
  
  
  const handleDeleteQuestion = (quesId) => {
    // Step 2: Open the delete confirmation modal
    setQuestionToDelete(quesId);
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.post(
        "https://myworxit.co.za/api/audits/deleteFronlineQuestion.php",
        {
          quesId: questionToDelete,
        }
      );

      if (response.data.status === "valid") {
        // Filter out the deleted question from adminQuestions
        setFrontlineQuestions((prevQuestions) => {
          return prevQuestions.filter((question) => question.quesId !== questionToDelete);
        });

        // Step 3: Close the delete confirmation modal
        setShowDeleteConfirmation(false);
      } else {
        console.error("Failed to delete question.");
      }
    } catch (err) {
      console.error(err);
      // Handle the error
    }
  };

  const sortedQuestions = [...frontlineQuestions].sort((a, b) => {
    const idA = parseInt(a.quesId.replace('QC', ''), 10);
    const idB = parseInt(b.quesId.replace('QC', ''), 10);
    return idA - idB;
  });


  return (
    <div>
      <button className="addQuestionBtn" onClick={() => setShowAddQuestion(true)}>Add Question</button>
      <table className="auditMainTbl">
        <thead>
          <tr>
            <th className='quesColumnWidth'>Questions</th>
            <th className='actionsColumnWidth'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedQuestions
          //.sort((a, b) => a.quesId(b.quesId))
          .map((details, index) => (
            <tr key={index}>
              <td className='quesColumnWidth'>{details.questions}</td>
              <td className='actionsColumnWidth'>
                <div className="actionsBtn">
                  <div className="editCont">
                    <button className="editBtn" onClick={() => handleEditClick(details.questions, details.quesId)}><FaEdit style={{ color: 'green' }} /></button>
                  </div>
                  <div className="deleteCont">
                    <button className="deleteBtn" onClick={() => handleDeleteQuestion(details.quesId)}><FaTimes style={{ color: 'red' }} /></button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
          {showAddQuestion && (
            <tr>
              <td className='quesColumnWidth'>
                <TextareaAutosize
                  type="text"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                  placeholder="Enter a new question"
                />
              </td>
              <td className='actionsColumnWidth'>
                <button onClick={handleAddQuestion}>Add</button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal isOpen={showDeleteConfirmation} toggle={() => setShowDeleteConfirmation(false)}>
        <ModalHeader toggle={() => setShowDeleteConfirmation(false)}>Confirm Deletion</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this question?
        </ModalBody>
        <ModalFooter>
          <Button className="updateBtn" onClick={confirmDelete}>
            Yes
          </Button>
          <Button className="cancelBtn" onClick={() => setShowDeleteConfirmation(false)}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showEditModal} toggle={() => setShowEditModal(false)} {...args}>
        <ModalHeader toggle={() => setShowEditModal(false)}>Edit Question</ModalHeader>
        <ModalBody>
        <label>Question ID:</label>
          <div>{editedQuestionId}</div>
          <label>Edit Question:</label>
          <TextareaAutosize
            type="text"
            value={editedQuestion}
            onChange={(e) => setEditedQuestion(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
        <Button className="updateBtn" onClick={handleUpdateQuestion}>Update</Button>
        <Button className="cancelBtn" onClick={() => setShowEditModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FrontlineSection;
