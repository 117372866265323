import React, {useState} from "react";
import axios from "axios";
import {  useParams } from "react-router-dom";
import domToImage from 'dom-to-image';
import { lpaFindingsState } from "../RecoilStates/LpaFindingsState";
import { lpaFrontlineDetailsState } from "../RecoilStates/LpaFrontlineDetailsState";
import { lpaSecurityDetailsState } from '../RecoilStates/LpaSecurityDetailsState';
import { lpaManagementDetailsState } from "../RecoilStates/LpaManagementDetailsState";
import { lpaCashOfficeDetailsState } from '../RecoilStates/LpaCashOfficeDetailsState';
import failCircleUrl from '../../media/failCircle.png';
import passCircleUrl from '../../media/passCircle.png';
import naCircleUrl from '../../media/naCircle.png';
import { useRecoilValue } from "recoil";
import { auditLogsAtom } from "../RecoilStates/auditlogsAtom";
import ClipLoader from "react-spinners/ClipLoader";

const ExportToPDF = ({chartRef, mapRef}) => {
  const { auditCode } = useParams();  
 const auditLogsData = useRecoilValue(auditLogsAtom)
  const findingsDetails = useRecoilValue(lpaFindingsState);
  const frontlineDetails = useRecoilValue(lpaFrontlineDetailsState);
  const securityDetails = useRecoilValue(lpaSecurityDetailsState);
  const ManagementDetailts = useRecoilValue(lpaManagementDetailsState)
  const cashOfficeDetails = useRecoilValue(lpaCashOfficeDetailsState);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  let [color, setColor] = useState("#ffffff");


  if (!Array.isArray(frontlineDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(securityDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  if (!Array.isArray(ManagementDetailts)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }


  if (!Array.isArray(cashOfficeDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  

  const filteredAuditLogs = auditLogsData.filter(details => details.auditCode === auditCode);
  const filteredFindingDetails = findingsDetails.filter(details => details.auditCode === auditCode);
  const filteredFrontlineDetails = frontlineDetails.filter(details => details.auditCode === auditCode);
  const filteredSecurityDetails = securityDetails.filter(details => details.auditCode === auditCode);
  const filteredManagementDetails = ManagementDetailts.filter(details => details.auditCode === auditCode);
  const filteredCashOfficeDetails = cashOfficeDetails.filter(details => details.auditCode === auditCode);

  
  const handleExportToPDFTest = async () => {
    setIsLoading(true);
    const filteredAuditCode = filteredAuditLogs.map(({ auditCode}) => auditCode).join(', ');
    const filteredAuditSite = filteredAuditLogs.map(({ site}) => site).join(', ');
    const filteredAuditUser = filteredAuditLogs.map(({ user}) => user).join(', ');
    const filteredAuditCompletionDate = filteredAuditLogs.map(({ completionDate}) => completionDate).join(', ');
    const filteredAuditLatitude = filteredAuditLogs.map(({ latitude }) => latitude).join(', ');
    const filteredAuditLongitude = filteredAuditLogs.map(({ longitude }) => longitude).join(', ');
    const filteredAuditAveragePercentage = filteredAuditLogs.map(({ averagePercentage }) => averagePercentage).join(', ');

    const chartImageUrl = await domToImage.toPng(chartRef.current);
    const mapImageUrl = await domToImage.toPng(mapRef.current);

    const dateTimeString = filteredAuditCompletionDate;
    const dateOnly = new Date(dateTimeString).toISOString().split('T')[0];

    try {
        const response = await fetch(`https://myworxit.co.za/api/audits/exportAuditToPDF/exportAuditToPDF.php?downloadPDF=true`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              filteredAuditCode: filteredAuditCode,
              filteredAuditSite: filteredAuditSite,
              filteredAuditUser: filteredAuditUser,
              filteredAuditCompletionDate: filteredAuditCompletionDate,
              filteredAuditLatitude: filteredAuditLatitude,
              filteredAuditLongitude: filteredAuditLongitude,
              filteredAuditAveragePercentage: filteredAuditAveragePercentage,

              chartImageUrl: chartImageUrl,
              mapImageUrl: mapImageUrl,

              failCircleUrl: failCircleUrl,
              passCircleUrl: passCircleUrl,
              naCircleUrl: naCircleUrl,

              filteredFindingDetails: filteredFindingDetails, // Pass the entire array directly
              filteredFrontlineDetails: filteredFrontlineDetails,
              filteredSecurityDetails: filteredSecurityDetails,
              filteredManagementDetails: filteredManagementDetails,
              filteredCashOfficeDetails: filteredCashOfficeDetails


            })
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
  
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filteredAuditSite}_${dateOnly}.pdf`);
          document.body.appendChild(link);
          link.click();
  
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          console.error('Failed to download PDF');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false); // Set loading state to false when export finishes
      }
    };

  return(
    
 <div>
      {isLoading ? (
          <div className="exportPDFBtnLoader">
          <ClipLoader label="Loading Spinner" color={"#ffffff"} loading={isLoading} size={32} />
          </div>
      ) : (
        <button className="exportPDFBtn" onClick={handleExportToPDFTest}>
          Export to PDF
        </button>
      )}        
      </div>
  
    )
  }    


    


  export default ExportToPDF;